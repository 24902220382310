@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.borders {
  border: 4px dashed #e5e0ff;
}
.leftalign {
  left: 170px;
  top: 5px;
  cursor: pointer;
}

/* @layer components{ */
  .profile_form{
    @apply text-black text-sm my-2  font-semibold
  }
  .profile_data{
    @apply text-black text-sm 

  }
  .profile_container{
    @apply text-black text-sm hover:bg-gray-200 p-2 ease-in duration-300 delay-150

  }
  .shadowhover{
    @apply shadow-md hover:shadow-2xl  ease-in duration-300 delay-150
  }
  .badge{
    @apply !bg-gray-200 px-2 rounded-sm shadow-sm text-sm text-black font-medium leading-3
  }
/* } */

*.ql-align-center {
  text-align: center !important;
}

*.ql-align-right {
  text-align: right !important;
}

*.ql-align-justify {
  text-align: justify !important;
}

.phonenumber {
  height: 160px !important;
}

.react-tel-input {
  height: 58px !important;
  /* margin: 8px 0px 0px 9px; */
}
.react-tel-input > input {
  height: 58px !important;
  width: 100% !important;
}

.react-tel-input .country-list {
  z-index: 5 !important;
}

.bg-change > div {
  min-height: 58px !important;
  z-index: 2;
}
.bg-changee > div {
  min-height: 58px !important;
  z-index: 0;
}

.w-27rem {
  width: 30rem;
}
.bttn {
  background: #03a9f4;
  color: #fff;
  border-radius: 6px;
  padding: 10px;
  width: 100px;
}
.css-4jx6zi .MuiTextField-root {
  margin: 0px !important;
}
.w-10\/12 {
  width: 99.333333% !important;
}
.css-zta0pv {
  margin-top: 40px;
  margin-left: 28px;
}
.languageGrid{
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 20px;
}
.qualificationGrid{
  display: grid;
  grid-template-columns: repeat(2,1fr);
}
.qualificationGridMain{
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 10px;
}
@media screen and (max-width:1023px) {
  .qualificationGridMain{
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(1,1fr);
  }
  .languageGrid{
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
  }
}
@media screen and (max-width:525px) {
  .languageGrid{
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 20px;
  }
}

.shadowMain{

box-shadow: 0px 0px 6px 1px #cfcaca !important; 
}
/* .phoneNumber {
  position: relative;
  display: flex;
  border: 1px #d6d3d3 solid;
  border-radius: 5px;
  background-color: white;
}
.PhoneInputCountrySelect {
  height: 55px;
  width: 65px;
  opacity: 1;
  outline: none;
  background-color: #efefef;
}
.PhoneInputCountryIconImg {
  position: absolute;
  top: 0px;
  height: 35px;
  width: 50px;
  background-color: #efefef;
  border-radius: 5px 0px 0px 5px;
}
.PhoneInputInput {
  position: absolute;
  top: 16px;
  left: 70px;
  width: 74%;
  outline: none;
  background-color: white;
}

.phoneInputs{
  z-index: 9;
} */

input {
  -webkit-text-fill-color: black !important;
  color: black;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: black !important;
}

.css-qiwgdb.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: black !important;
}
.MuiInputBase-inputMultiline {
  opacity: 1;
  -webkit-text-fill-color: black !important;
}

.dpdn {
  margin: 16px 0px 0px 9px !important;
}

.hider {
  display: none;
}
@media screen and (min-width: 600px) {
  .hider  {
    display: block;
  }
}

.sub-headings {
  font-size: 16px;
  font-weight: 500;
  color: rgb(88, 88, 88);
}

.backButton {
  display: flex ;
  align-items: center;
  color: #ff6500 !important;
  background-color: white !important;
  border: 2px solid #ff6500 !important;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 14px;
}
.backButton:hover {
  /* background-color: #ff6500;
  color: white; */
  box-shadow: 0 2px 12px 1px #797979;
  border: 2px solid #ff6500;
}

.backButton:active {
  background-color: #b6b5b5 !important;
  color: white !important;
  /* border: 2px solid #b6b5b5 !important; */
  border: none !important;
}

.allButton {
  display: flex;
  align-items: center;
  color: white;
  background-color: #ff6500;
  border: 2px solid #ff6500;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 18px !important;
}
.allButton:hover {
  color: #ffffff !important;
  background-color: #ff6500 !important;
  box-shadow: 0 2px 12px 1px #797979 !important;
}
.allButton:focus {
  background-color: #ff6500 !important; 
}

.allButton:active {
  background-color: #e95d00 !important;
  border: 2px solid #e95d00 !important;
}


.signinButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #ff6500;
  width: 100vw;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 18px !important;
  letter-spacing: 1px;
}
.signinButton:hover {
  color: #ffffff !important;
  background-color: #ff6500 !important;
  box-shadow: 0 2px 12px 1px #797979 !important;
}
.signinButton:focus {
  background-color: #ff6500 !important; 
}

.signinButton:active {
  background-color: #e95d00 !important;
  border: 2px solid #e95d00 !important;
}

@media only screen and (min-width: 600px) {
  .backButton {
    margin-right: 10px;
  }
  .copyBtn {
    margin-left: 10px;
  }
}

.errorMessage {
  color: rgb(231, 2, 2);
  font-size: small;
  font-family: serif;
  font-weight: 400;
  word-spacing: 2px;
}

.priceWheel {
  touch-action: none;
}

.breakWord{

  word-break: break-all !important;
}
.leftArrow::before{
  content: '';
  display: block;
  width: 2px;
  height: 20px;
  background-color: black;
  transform: rotate(-45deg) translate(-15px, 4px);
  /* left: 0;
  top: 0; */
  bottom: 1px;

}

.historyLogs{
  max-height: 600px; /* Adjust this to your container's maximum height */
  max-height: 600px; /* Adjust this to your container's maximum height */
  overflow-y: scroll;
}
.historyLogss{
  max-height: 580px; /* Adjust this to your container's maximum height */
  max-height: 580px; /* Adjust this to your container's maximum height */
  overflow-y: scroll;
}


.enquireScroll {
  min-height: 450px; /* Adjust this to your container's maximum height */
  max-height: 450px; /* Adjust this to your container's maximum height */
  overflow-y: scroll;
  /* scroll-snap-type: y mandatory; */
  /* scroll-padding-bottom: 150px; */
}
.enquireScrollOrder {
  /* min-height: 450px; */
   /* Adjust this to your container's maximum height */
  max-height: 450px; /* Adjust this to your container's maximum height */
  overflow-y: scroll;
  /* scroll-snap-type: y mandatory; */
  /* scroll-padding-bottom: 150px; */
}

/* .chat {
  scroll-snap-align: end;
} */

@media only screen and (min-width: 600px) {
  .orderScroll {
    height: 650px;
    overflow: scroll;
  }
}


.enquiremsg {
  background-color: #d9d9d9;
  color: #2e2e2e;
  padding: 6px 0;
}

@media only screen and (max-width: 1023px) {
  .historyLog {
    margin-top: 24px;
  }
}

.accordion {
  /* --bs-accordion-color: #ff6500 !important; */
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0;
  --bs-accordion-inner-border-radius: calc(0.375rem - 5px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #3b271a;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='b84a01'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
  --bs-accordion-btn-focus-border-color: #ff6500;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgb(196, 62, 0);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: white;
  --bs-accordion-active-bg: #ff6500;
  --bs-accordion-active-border-radius: 1rem !important;
  /* --bs-accordion-active-border-radius: 5px; */
  outline: none !important;
}
.accordion-button:focus {
  /* z-index: 3; */
  /* border-radius: 15px !important; */
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion-body {
  padding: 0px !important;
}

.buttons {
  display: flex;
  align-items: center;
  color: white;
  background-color: #ff6500;
  width: 100vw;
  font-size: 14px;
  font-weight: bold;
}

@media only screen and (max-width: 430px) {
  .tableView {
    width: 350px;
    overflow: scroll;
  }
}
@media only screen and (max-width: 360px) {
  .tableView {
    width: 300px;
    overflow: scroll;
  }
}

.arrow-left {
  position: absolute;
  width: 0; 
  height: 0; 
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent; 
  
  border-right:6px solid #d2d6de; 
  left: -6px;
}
.names{
  display: none;
}
.avatarHover:hover .names{
  display: block !important;
  position: absolute;
  top: 10px;

}


.bgGray{
  position: relative;
  border-radius: 4px;
  margin-left: 15px !important;
  background-color: #d2d6de !important;
}

.maxHeight > .css-q8hpuo-MuiFormControl-root > .MuiInputBase-root {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 3.6876em !important;
  letter-spacing: 0.00938em;
}
.maxHeight  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
padding: 0px !important;
}
.radioInput {
  cursor: pointer;
}
.textalign  {
  text-align: justify;
}

.linClamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.titleLinClamp {
  line-height: 40px !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

/* .orderCard{
  max-height: 420px;
  min-height: 420px;
} */

.studentDetailsGrid{
  display: grid;
  grid-template-columns:repeat(2,1fr) ;
}
/* .studentDetailsGridMain{
  display: grid;
  grid-template-columns:repeat(3,fr) ;
} */
@media only screen and (max-width: 1024px) {
  .orderCard {
    max-height: 400px;
    min-height: 400px;
  }
}
@media only screen and (min-width: 445px) {
  .orderCard {
    max-height: 410px;
    min-height: 410px;
  }
}

/* .MuiFormControl-root MuiTextField-root{
  padding-top: 3px !important;
} */
@media only screen and (max-width: 750px) {
  .cardText {
    width: 50%;
  }
  .cardText2 {
    width: fit-content;
    font-size: 12px;
  }
}
@media only screen and (max-width: 750px) {
  .cardText {
    width: 50%;
  }
  .cardText2 {
    width: fit-content;
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .cardText {
    width: 40%;
    font-size: 20px;
  }
  .cardText2 {
    width: fit-content;
    font-size: 10px;
  }
}
@media only screen and (min-width: 750px) and (max-width: 768px) {
  .cardText {
    width: 40%;
    font-size: 20px;
  }
  .cardText2 {
    width: fit-content;
    font-size: 10px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .cardText {
    width: 45%;
    font-size: 20px;
  }
  .cardText2 {
    width: fit-content;
    font-size: 12px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1497px) {
  .cardText {
    width: 45%;
    font-size: 20px;
  }
  .cardText2 {
    width: fit-content;
    font-size: 12px;
  }
}
@media only screen and (min-width: 1497px) {
  .cardText {
    width: 55%;
    font-size: 20px;
  }
  .cardText2 {
    width: fit-content;
    font-size: 12px;
  }
}


.custom-dropdown{
  min-width: 100px;
  padding: 10px;
}

.custom-dropdown .muiSelect-root{
  font-size: inherit;
}
.profileImg{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 50px;
}